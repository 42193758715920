/* eslint-disable indent */
import React, { Component } from 'react'
import {
  Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { LoginMenu } from './api-authorization/LoginMenu'
import './NavMenu.css'
import authService from './api-authorization/AuthorizeService'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import { PrivacyModeContext } from './../contexts/PrivacyModeContext'

export class NavMenu extends Component {
  static displayName = NavMenu.name
  constructor (props) {
    super(props)

    this.state = {
      userRole: null
    }
  }

  componentDidMount () {
    this.getUser().then(user => {
      if (user != null) {
        this.setState({ userRole: user.role })
      }
    })
  }

  getUser () {
    return authService.getUser()
  };

  render () {
    if ((this.state.userRole) == null) {
      return (
        <div></div>
      )
    } else if (this.state.userRole === 'Administrator') {
      return (
        <header>
          <AdminNavbar />
        </header>
      )
    } else if (this.state.userRole === 'RelationshipManager') {
      return (
        <header>
          <RelationshipManagerNavbar />
        </header>
      )
    } else if (this.state.userRole === 'PortfolioManager') {
      return (
        <header>
          <PortfolioManagerNavbar />
        </header>
      )
    } else {
      return (
        <header>
          <DefaultNavbar/>
        </header>
      )
    };
  };
}

class PortfolioManagerNavbar extends Component {
  static contextType = PrivacyModeContext
  constructor (props) {
    super(props)

    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.handlePrivacyModeToggle = this.handlePrivacyModeToggle.bind(this)
    this.state = {
      collapsed: true,
      privacyMode: false
    }
  };

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    })
  };

  handlePrivacyModeToggle () {
    const { togglePrivacyMode } = this.context
    togglePrivacyMode()
  };

  componentDidMount () {
    const { isPrivacyMode } = this.context
    const currMode = isPrivacyMode ? false : isPrivacyMode
    this.setState({
      privacyMode: currMode
    })
  }

  render () {
    const { isPrivacyMode } = this.context
    return (
      <Navbar color="dark" className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow" light>
        <NavbarBrand className="text-light" tag={Link} to="/" style={{ padding: 0 }}>
          <img
            alt=""
            src="/favicon.ico"
            width="30"
            height="30"
            style={{ marginBottom: 7 }}
          />
          <span style={{ marginLeft: 12, marginRight: 10 }}>Mermaid Portal</span>
        </NavbarBrand>
        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
          <ul className="navbar-nav flex-grow">
            <NavItem>
              <NavLink tag={Link} className="text-white" to="/Portfolio">Min portefølje</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-white" to="/Transaction">Transaktioner</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-white" to="/Document">Dokumenter</NavLink>
            </NavItem>
            <NavItem>
              <UncontrolledDropdown inNavbar>
                <DropdownToggle className="text-white" nav caret>
                                    Administration
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem tag={Link} to="/ClientManage">Kundestyring</DropdownItem>
                  <DropdownItem tag={Link} to="/UserManage">Portal brugere</DropdownItem>
                  <DropdownItem tag={Link} to="/Gold">007 GOLD</DropdownItem>
                  <DropdownItem tag={Link} to="/GoldReport">007 GOLD Rapport</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            <NavItem>
              <IconButton
                onClick={this.handlePrivacyModeToggle}
                style={{ marginLeft: '8px' }}
                title={isPrivacyMode ? 'Skift til "Skjul følsom information"' : 'Skift til "Skjul ikke følsom information"'}
              >
                {isPrivacyMode ? <VisibilityOffIcon className="text-white" /> : <RemoveRedEyeIcon className="text-white" />}
              </IconButton>
            </NavItem>
            <LoginMenu>
            </LoginMenu>
          </ul>
        </Collapse>
      </Navbar>
    )
  };
}

class AdminNavbar extends Component {
  static contextType = PrivacyModeContext
  constructor (props) {
    super(props)

    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.handlePrivacyModeToggle = this.handlePrivacyModeToggle.bind(this)
    this.state = {
      collapsed: true,
      privacyMode: false
    }
  };

  handlePrivacyModeToggle () {
    const { togglePrivacyMode } = this.context
    togglePrivacyMode()
  };

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    })
  };

  componentDidMount () {
    const { togglePrivacyMode } = this.context
    const storedSettings = JSON.parse(localStorage.getItem('settings'))
    const privacyMode = storedSettings?.isPrivacyMode ?? false
    if (privacyMode) togglePrivacyMode()
    // Update the component's state based on the privacy mode
    this.setState({
      privacyMode
    })
  }

  render () {
    const { isPrivacyMode } = this.context
    return (
      <Navbar color="dark" className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow" light>
        <NavbarBrand className="text-light" tag={Link} to="/" style={{ padding: 0 }}>
          <img
            alt=""
            src="/favicon.ico"
            width="30"
            height="30"
            style={{ marginBottom: 7 }}
          />
          <span style={{ marginLeft: 12, marginRight: 10 }}>Mermaid Portal</span>
        </NavbarBrand>
        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
          <ul className="navbar-nav flex-grow">
            <NavItem>
              <NavLink tag={Link} className="text-white" to="/Portfolio">Min portefølje</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-white" to="/Transaction">Transaktioner</NavLink>
            </NavItem>
            <NavItem>
              <UncontrolledDropdown inNavbar>
                <DropdownToggle className="text-white" nav caret>
                  Dokumenter
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem tag={Link} to="/Document">Dokumenter</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem tag={Link} to="/IssueDocument/Report">Udgiv afkastrapporter</DropdownItem>
                  <DropdownItem tag={Link} to="/IssueDocument/Invoice">Udgiv faktura</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            <NavItem>
              <UncontrolledDropdown inNavbar>
                <DropdownToggle className="text-white" nav caret>
                  Administration
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem tag={Link} to="/Processes">Processer</DropdownItem>
                  <DropdownItem tag={Link} to="/ClientManage">Kundestyring</DropdownItem>
                  <DropdownItem tag={Link} to="/UserManage">Portal brugere</DropdownItem>
                  <DropdownItem tag={Link} to="/Gold">007 GOLD</DropdownItem>
                  <DropdownItem tag={Link} to="/GoldReport">007 GOLD Rapport</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            <NavItem>
              <IconButton
                onClick={this.handlePrivacyModeToggle}
                style={{ marginLeft: '8px' }}
                title={isPrivacyMode ? 'Skift til "Skjul følsom information"' : 'Skift til "Skjul ikke følsom information"'}
              >
                {isPrivacyMode ? <VisibilityOffIcon className="text-white" /> : <RemoveRedEyeIcon className="text-white" />}
              </IconButton>
            </NavItem>
            <LoginMenu>
            </LoginMenu>
          </ul>
        </Collapse>
      </Navbar>
    )
  };
}

class DefaultNavbar extends Component {
  constructor (props) {
    super(props)

    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.state = {
      collapsed: true
    }
  };

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    })
  };

  render () {
    return (
      <Navbar color="dark" className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow" light>
        <NavbarBrand className="text-light" tag={Link} to="/" style={{ padding: 0 }}>
          <img
            alt=""
            src="/favicon.ico"
            width="30"
            height="30"
            style={{ marginBottom: 7 }}
          />
          <span style={{ marginLeft: 12, marginRight: 10 }}>Mermaid Portal</span>
        </NavbarBrand>
        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
          <ul className="navbar-nav flex-grow">
            <NavItem>
              <NavLink tag={Link} className="text-white" to="/Portfolio">Portefølje oversigt</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-white" to="/Transaction">Transaktioner</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-white" to="/Document">Dokumenter</NavLink>
            </NavItem>
            <LoginMenu>
            </LoginMenu>
          </ul>
        </Collapse>
      </Navbar>
    )
  };
}

class RelationshipManagerNavbar extends Component {
  static contextType = PrivacyModeContext
  constructor (props) {
    super(props)

    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.handlePrivacyModeToggle = this.handlePrivacyModeToggle.bind(this)
    this.state = {
      collapsed: true,
      privacyMode: false
    }
  };

  handlePrivacyModeToggle () {
    const { togglePrivacyMode } = this.context
    togglePrivacyMode()
  };

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    })
  };

  componentDidMount () {
    const { isPrivacyMode } = this.context
    const currMode = isPrivacyMode ? false : isPrivacyMode
    this.setState({
      privacyMode: currMode
    })
  }

  render () {
    const { isPrivacyMode } = this.context
    return (
      <Navbar color="dark" className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow" light>
        <NavbarBrand className="text-light" tag={Link} to="/" style={{ padding: 0 }}>
          <img
            alt=""
            src="/favicon.ico"
            width="30"
            height="30"
            style={{ marginBottom: 7 }}
          />
          <span style={{ marginLeft: 12, marginRight: 10 }}>Mermaid Portal</span>
        </NavbarBrand>
        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
          <ul className="navbar-nav flex-grow">
            <NavItem>
              <NavLink tag={Link} className="text-white" to="/Portfolio">Min portefølje</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-white" to="/Transaction">Transaktioner</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-white" to="/Document">Dokumenter</NavLink>
            </NavItem>
            <NavItem>
              <UncontrolledDropdown inNavbar>
                <DropdownToggle className="text-white" nav caret>
                  Administration
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem tag={Link} to="/ClientManage">Kundestyring</DropdownItem>
                  <DropdownItem tag={Link} to="/UserManage">Portal brugere</DropdownItem>
                  <DropdownItem tag={Link} to="/Gold">007 GOLD</DropdownItem>
                  <DropdownItem tag={Link} to="/GoldReport">007 GOLD Rapport</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            <NavItem>
              <IconButton
                onClick={this.handlePrivacyModeToggle}
                style={{ marginLeft: '8px' }}
                title={isPrivacyMode ? 'Skift til "Skjul følsom information"' : 'Skift til "Skjul ikke følsom information"'}
              >
                {isPrivacyMode ? <VisibilityOffIcon className="text-white" /> : <RemoveRedEyeIcon className="text-white" />}
              </IconButton>
            </NavItem>
            <LoginMenu>
            </LoginMenu>
          </ul>
        </Collapse>
      </Navbar>
    )
  };
}
