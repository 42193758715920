/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, { useEffect, useState } from 'react'
import AppRoutes from './AppRoutes'
import authService from './components/api-authorization/AuthorizeService'
import { Layout } from './components/Layout'
import { Loading } from './components/Loading'
import './custom.css'

const App = () => {
  const [userRole, setUserRole] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchUserRole = async () => {
      authService.ensureUserManagerInitialized()
      const isAuthenticated = await authService.isAuthenticated()
      if (isAuthenticated) {
        const user = await authService.getUser()
        setUserRole(user.role)
      }
      setLoading(false)
    }
    fetchUserRole()
  }, [])

  if (loading) {
    return <Loading/>
  }

  return (
    <Layout>
      <AppRoutes userRole={userRole} />
    </Layout>
  )
}

export default App
