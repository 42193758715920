/* eslint-disable react/prop-types */
import React, { Component } from 'react'

export class Loading extends Component {
  static displayName = Loading.name

  render () {
    return (
      <div>
        <div className="main">
          {this.props.children}
        </div>
      </div>
    )
  }
}
