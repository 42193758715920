/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState } from 'react'

// Create a Context for the Privacy Mode
export const PrivacyModeContext = createContext()

// Provider component
export const PrivacyModeProvider = ({ children }) => {
  const [isPrivacyMode, setPrivacyMode] = useState(false)

  const togglePrivacyMode = () => {
    setPrivacyMode(!isPrivacyMode)

    const currentData = JSON.parse(localStorage.getItem('settings')) || {} // Get current data or initialize as empty object
    const updatedData = { ...currentData, ...{ isPrivacyMode: !isPrivacyMode } } // Merge new data with existing data
    localStorage.setItem('settings', JSON.stringify(updatedData)) // Save updated data to local storage
  }
  return (
    <PrivacyModeContext.Provider value={{ isPrivacyMode, togglePrivacyMode }}>
      {children}
    </PrivacyModeContext.Provider>
  )
}

// Custom hook to use the Privacy Mode context
export const usePrivacyMode = () => useContext(PrivacyModeContext)
