/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { NavMenu } from './NavMenu'

export class Layout extends Component {
  static displayName = Layout.name

  render () {
    return (
      <div>
        <div className="main">
          <NavMenu />
          {this.props.children}
        </div>
      </div>
    )
  }
}
